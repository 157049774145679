// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require.context('../images', true)
import '../stylesheets/application'
import 'jquery/src/jquery';
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
import '../src/index';
import "../components"

// import Rails from 'rails-ujs';
// import Turbolinks from 'turbolinks';

// Rails.start();
// Turbolinks.start();


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import { Autocomplete } from "stimulus-autocomplete"

window.Stimulus = Application.start()
const context = require.context("../controllers", true, /\.js$/)
window.Stimulus.load(definitionsFromContext(context))
window.Stimulus.register("autocomplete", Autocomplete)

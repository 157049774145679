import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'form',
    'setListNameInput',
    'submitCopyButton'
  ]

  /**
   * triggered when the form submit button is clicked
   */
  submitForm() {
    const $form = $(this.formTarget)
    this.appendSongsIdsToForm($form)
    
    $form.trigger('submit.rails')
  }

  /**
   * triggered when the set list select is changed
   */
  setListSelectChanged(event) {
    // If a set list is selected, hide the set list name input and enable the submit button
    // Otherwise, show the set list name input and disable the submit button if the set list name input is empty
    if (event.target.value) {
      this.setListNameInputTarget.classList.add('d-none')
      this.submitCopyButtonTarget.removeAttribute('disabled')
    } else {
      this.setListNameInputTarget.classList.remove('d-none')
      if (!this.setListNameInputTarget.value) {
        this.submitCopyButtonTarget.setAttribute('disabled', 'disabled')
      }
    }
  }

  /**
   * triggered when the set list name input is changed
   */
  setListNameInputChanged(event) {
    // If the set list name input is empty, disable the submit button, enable otherwise
    if (event.target.value) {
      this.submitCopyButtonTarget.removeAttribute('disabled')
    } else {
      this.submitCopyButtonTarget.setAttribute('disabled', 'disabled')
    }
  }

  /**
   * Appends the selected songs ids to the form
   */
  appendSongsIdsToForm = ($form) => {
    const songTableController = this.application.getControllerForElementAndIdentifier(document.querySelector('[data-controller="song-table"]'), 'song-table')
    const selectedSongs = songTableController.rowsCheckedValue.map(song => song.id)

    $form.find('[name="songs[]"]').remove()
    selectedSongs.forEach(selectedSong => {
      $form.append(`<input type="hidden" name="songs[]" value="${selectedSong}">`)
    })
  }
}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'setListSelect',
    'setListNameInput',
    'addToSetListButton'
  ]

  static values = {
    setListsNames: Array
  }

  initialize() {
    this.updateFormStatus()
  }

  setListSelectChanged() {
    this.updateFormStatus()
  }

  setListNameInputChanged() {
    this.updateFormStatus()
  }

  updateFormStatus() {
    // If a set list is selected:
    // - enable the "Add to set list" button
    // - disable the set list name input
    if (this.setListSelectTarget.value) {
      this.addToSetListButtonTarget.removeAttribute('disabled')
      this.setListNameInputTarget.setAttribute('disabled', 'disabled')
      return
    }
    
    // If the set list name input is not empty:
    if (this.setListNameInputTarget.value) {
      // If the set list select input value already exists in the setListName target:
      if (this.setListNameExists(this.setListNameInputTarget)) {
        // - disable the "Add to set list" button
        // - enable the set list name input
        this.addToSetListButtonTarget.setAttribute('disabled', 'disabled')
        this.setListNameInputTarget.removeAttribute('disabled')
      } else { // Otherwise:
        // - enable the "Add to set list" button
        // - enable the set list name input
        this.addToSetListButtonTarget.removeAttribute('disabled')
        this.setListNameInputTarget.removeAttribute('disabled')
      }
      return
    }
    
    // Otherwise:
    // - disable the "Add to set list" button
    // - enable the set list name input
    this.addToSetListButtonTarget.setAttribute('disabled', 'disabled')
    this.setListNameInputTarget.removeAttribute('disabled')
  }

  /**
   * Check if the set list select input value already exists in the setListName values
   */
  setListNameExists(input) {
    return this.setListsNamesValue.some((name) => name.toLowerCase() === input.value.toLowerCase())
  }
}

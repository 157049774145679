//import AOS from "./vendor/aos";
import init_select2 from "./init_select2.js";
import Croppie from "croppie";

$( document ).ready( function() {
  if ( typeof init_select2 === "function" ) {
    init_select2();
  }

  let el = document.getElementById( "upload-demo" );
  let up;
  $( document ).on( "change", ".upload-box__input", function() {
    readFile( this );
  } );

  let otherFluentLanguages = $( ".js-select2-languages" );
  let otherFluentLanguagesField = $( ".other-fluent-languages" );
  if ( otherFluentLanguages && otherFluentLanguagesField ) {
    otherFluentLanguages.on( "change", function( evt ) {

        if ( evt.currentTarget.value &&
            evt.currentTarget.value === "Other" ||
            otherFluentLanguages.val().includes( "Other" ) ) {

          otherFluentLanguagesField.css( "display", "block" );
        } else {
          otherFluentLanguagesField.css( "display", "none" );
          $( "#volunteer_application_other_fluent_languages" ).val( "" );
        }
    } );
  }

  // WYSIWYG Editor
  function wysiwygEditor_init(){
    if(!tinymce.editors.length)
      tinymce.init( {
        selector: ".wysiwyg-editor",
        plugins: "lists link",
        removePlugins: ['font'],
        menubar: false,
        toolbar: "bold italic underline link | bullist"
      } );
  }

  let wysiwygEditor = $( ".wysiwyg-editor" );
  if ( wysiwygEditor.length ) {

    if(wysiwygEditor.closest('.modal').length){
      $( document ).on( "show.bs.modal", ".modal", function() {
        wysiwygEditor_init();
      } );

      $( document ).on( "hide.bs.modal", ".modal", function() {
        tinymce.activeEditor.destroy();``
      } );
    }
    else wysiwygEditor_init();
  }

  function init_ah_pagination() {
    let approvedHospitalPagination = $( "[data-approved-hospital-pagination]" );
    if ( approvedHospitalPagination.length > 0 ) {
      approvedHospitalPagination.pagination( {
        pages: $( "#totalPages" ).data( "total-pages" ),
        itemsOnPage: 15,
        cssStyle: "light-theme",
        onPageClick: function( pageNumber, e ) {
          $( ".approved-hospital-page" ).attr( "hidden", true );
          $( `#approved-hospital-page-${pageNumber}` ).attr( "hidden", false );
        }
      } );
    }
  }
  init_ah_pagination();

  // $(".rotate").on("click", function () {
  //   console.log('rotate');
  //   up.rotate(parseInt($(this).data('deg')));
  // });

  function readFile( input ) {
    if ( input.files && input.files[ 0 ] ) {
      let reader = new FileReader();

      reader.onloadstart = function( e ) {
        $( ".image-loader" ).addClass( "shown" );
      };
      reader.onload = function( e ) {
        e.stopPropagation();
        setTimeout( function() {
          $( ".upload-box__placeholder" ).addClass( "uploaded-image" );
          $( ".image-loader" ).removeClass( "shown" );
          $( ".upload-box-wrapper" ).addClass( "ready" );

          let viewbox = $( ".upload-demo-croppie" );
          let view_w = viewbox.width();
          let view_h = viewbox.height();

          if ( !up ) {
            up = new Croppie( el, {
              viewport: {
                width: view_w,
                height: view_h,
                type: "square"
              },
              enableExif: true,
              enableOrientation: true,
              boundary: { width: view_w, height: view_h }
            } );
          }
          up.bind( { url: e.target.result } );

          $( ".rotate" ).on( "click", function() {
            console.log( "rotate" );
            up.rotate( parseInt( $( this ).data( "deg" ) ) );
          } );
        }, 1000 );
      };

      reader.readAsDataURL( input.files[ 0 ] );
    } else {
      console.log( "Sorry - you're browser doesn't support the FileReader API" );
    }
  }

  $( "#uploadButton" ).on( "click", function( e ) {
    e.preventDefault();
    console.log( "#uploadButton" );

    $( "#uploadButton" ).text( "Please wait..." );
    $( "#uploadButton" ).attr( "disabled", true );

    setTimeout( uploadPhotoAndSubmit, 2000 );

    return false;
  } );

  function uploadPhotoAndSubmit() {
    console.log( "uploadPhotoAndSubmit" );

    let $form = $( "#profileForm" );
    let formData = new FormData();

    $.each( $form.data( "form-data" ), function( key, value ) {
      formData.append( key, value );
    } );

    up.result( { type: "blob" } ).then( function( resp ) {
      console.log( "resp", resp );

      setTimeout( function() {
        formData.append( "file", resp, "avatar.png" );
      }, 1500 );
    } );


    setTimeout( function() {
      $.ajax( {
        url: $form.data( "url" ),
        data: formData,
        processData: false,
        contentType: false,
        type: "POST",
        success: function( res ) {
          console.log( "upload result", $( res ).find( "Location" ).text() );

          let img_url = $( res ).find( "Location" ).text();

          $.ajax( {
            dataType: "json",
            method: "PUT",
            url: "/save_avatar",
            data: {
              url: img_url
            },
            success: function( data ) {
              console.log( "saved" );

              $( "#uploadButton" ).text( "Avatar uploaded!" );
              $( ".upload-box__placeholder" ).removeClass( "uploaded-image" );
              $( ".upload-box-wrapper" ).removeClass( "ready" );
              $( ".image-loader" ).empty();
              $( ".upload-box__placeholder" ).empty();
              $( "#upload" ).attr( "disabled", true );

              if ( up ) {
                up.destroy();
              }
              $( "#upload-demo" ).append( `<img src="${img_url}">` );

              setTimeout( function() {
                document.location.reload(); //reload current page url
              }, 2000 );
            }
          } );
        },
        error: function( res ) {
          console.log( "error", res );
        },
        complete: function( res ) {
          console.log( "complete = ", res );
        }
      } );
    }, 3000 );
  }

  function base64ToBlob( base64, mime ) {
    mime = mime || "";
    let sliceSize = 1024;
    let byteChars = window.atob( base64 );
    let byteArrays = [];

    for ( let offset = 0, len = byteChars.length; offset < len; offset += sliceSize ) {
      let slice = byteChars.slice( offset, offset + sliceSize );

      let byteNumbers = new Array( slice.length );
      for ( let i = 0; i < slice.length; i++ ) {
        byteNumbers[ i ] = slice.charCodeAt( i );
      }

      let byteArray = new Uint8Array( byteNumbers );

      byteArrays.push( byteArray );
    }

    return new Blob( byteArrays, { type: mime } );
  }


  // NOTIFICATION MODALS

  let searchParams = new URLSearchParams( window.location.search );
  let showAlert = searchParams.get( "show_confirmation_message" );
  let showRmShiftAccepted = searchParams.get( "rm_shift_invitation_accepted" );
  let showRmShiftDenied = searchParams.get( "rm_shift_invitation_denied" );
  let showShiftConfirmed = searchParams.get( "shift_confirmed" );
  let showShiftInvitationDenied = searchParams.get( "shift_invitation_denied" );

  if ( showAlert ) {
    $( "#alertModal" ).modal();
  }

  if (showRmShiftAccepted)
    $('#rvShiftAcceptedModal').modal();

  if (showRmShiftDenied)
    $('#rvShiftDeniedModal').modal();

  if ( showShiftConfirmed ) {
    $( "#shiftConfirmedModal" ).modal();
  }

  if ( showShiftInvitationDenied ) {
    $( "#shiftInvitationDeniedModal" ).modal();
  }


  // DELETE MODAL TRIGGER HELPER FOR DYNAMIC: ONE MODAL - MANY TRIGRES
  $( "[data-confirm-delete-trigger]" ).on( "click", function() {
    let targetModal = $( this ).data( "target");
    let deleteItem = $( this ).data( "confirm-delete-trigger");
    let deletePath = $( this ).data( "path");
    $( targetModal+" .confirmDeleteLink" ).attr( "href", deletePath );
    $( targetModal+" .confirmDeleteItem" ).html( deleteItem );
  } );


  // OPEN ADD SHIFT MODAL

  let showAddShift = searchParams.get( "add_shift_modal" );

  if ( $( "#newShift" ).length && showAddShift ) {
    $( "#newShift" ).modal();
  }

  // Initialize Bootstrap Tooltips
  if ($('[data-toggle="tooltip"]').length) {
    $('[data-toggle="tooltip"]').tooltip()
  }

  // Initialize Bootstrap Popovers
  if ($('[data-toggle="popover"]').length) {
    $('[data-toggle="popover"]').popover()
  }

} );


let modalStartPlace;
$( document ).on( "show.bs.modal", ".modal", function() {
  modalStartPlace = $( this ).parent();
  $( this ).appendTo( "body" );
} );

$( document ).on( "hide.bs.modal", ".modal", function() {
  let modalDataStartPlace = $( this ).data( "modal-start-place" );
  modalStartPlace = ( modalDataStartPlace ) ? modalDataStartPlace : modalStartPlace;

  $( this ).appendTo( modalStartPlace );
} );

//multimodal fix
$( document ).on( {
  "show.bs.modal": function() {
    let zIndex = 1040 + ( 10 * $( ".modal:visible" ).not( this ).length );
    $( this ).css( "z-index", zIndex );
    setTimeout( function() {
      $( ".modal-backdrop" ).not( ".modal-stack" ).css( "z-index", zIndex - 1 ).addClass( "modal-stack" );
    }, 0 );
  },
  "hidden.bs.modal": function() {
    if ( $( ".modal:visible" ).length > 0 ) {

      // restore the modal-open class to the body element, so that scrolling works
      // properly after de-stacking a modal.
      setTimeout( function() {
        $( document.body ).addClass( "modal-open" );
      }, 0 );
    }
  }
}, ".modal" );

// Prevent Bootstrap dialog from blocking focusin
$(document).on('focusin', function(e) {
  if ($(e.target).closest(".tox-tinymce, .tox-tinymce-aux, .moxman-window, .tam-assetmanager-root").length) {
    e.stopImmediatePropagation();
  }
});

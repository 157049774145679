import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'artistInput',
    'titleInput',
    'songsApproved',
    'songsPending',
    'songsRejected',
    'songStatusMessage',
    'submitButton',
    'subscribeSubmitButton'
  ]

  static values = {
    songsApproved: Array,
    songsPending: Array,
    songsRejected: Array
  }

  connect(){
    const autocompleteArtist = document.getElementById('autocomplete-artist')
    const autocompleteSong = document.getElementById('autocomplete-song')

    autocompleteArtist.addEventListener('autocomplete.change', this.autocompleteArtistChange.bind(this))
    
    autocompleteSong.addEventListener('load', this.autocompleteSongLoad.bind(this))
    autocompleteArtist.addEventListener('load', this.autocompleteSongLoad.bind(this))

  }

  /**
   * Triggered when the artist input value changes by using the autocomplete
   */
  autocompleteArtistChange(event) {
    this.updateAutocompleteSongUrl(event.detail.value)
    this.updateSongStatusMessage()
  }

  /**
   * Triggered when the artist input value changes by typing
   */
  artistInputChange(event) {
    this.updateAutocompleteSongUrl(event.target.value)
    this.updateSongStatusMessage()
  }

  /**
   * Triggered when the song title input value changes by typing
   */
  titleInputChange() {
    this.updateSongStatusMessage()
  }

  /**
   * Triggered when the song autocomplete controller loads the results
   */
  autocompleteSongLoad() {
    this.songsApprovedValue = this.extractSongsFromTargets(this.songsApprovedTargets)
    this.songsPendingValue = this.extractSongsFromTargets(this.songsPendingTargets)
    this.songsRejectedValue = this.extractSongsFromTargets(this.songsRejectedTargets)

    this.updateSongStatusMessage()
  }

  /**
   * Update the URL of the autocomplete song controller based on the artist value
   */
  updateAutocompleteSongUrl(value) {
    const autocompleteController = this.application.getControllerForElementAndIdentifier(document.getElementById('autocomplete-song'), 'autocomplete')
    autocompleteController.urlValue = `/volunteer/songs/autocomplete_songs?artist=${value}`
  }

  /**
   * Update the song status message based on the song input value.
   * If the song exists, is pending or rejected, show the message.
   * Otherwise, hide it.
   */
  updateSongStatusMessage() {
    const artist = this.artistInputTarget.value.toLowerCase()
    const title = this.titleInputTarget.value.toLowerCase()
    const inputSong = { artist, title }

    let message = ''
    let song = null
    this.subscribeSubmitButtonTarget.classList.add('d-none')


    if (this.findSong(this.songsApprovedValue, inputSong)) {
      message = 'This song already exists.'
    } else if (song = this.findSong(this.songsPendingValue, inputSong)) { // skipcq: JS-0001
      message = 'This song was already requested and is pending approval.'

      this.subscribeSubmitButtonTarget.classList.remove('d-none')
      this.subscribeSubmitButtonTarget.href = `/volunteer/songs/${song.id}/subscribe`

    } else if (this.findSong(this.songsRejectedValue, inputSong)) {
      message = 'This song was already requested and was rejected.'
    }

    this.songStatusMessageTarget.textContent = message

    if (message) {
      this.songStatusMessageTarget.classList.remove('d-none')
      this.submitButtonTarget.setAttribute('disabled', 'disabled')
    } else {
      this.songStatusMessageTarget.classList.add('d-none')
      this.submitButtonTarget.removeAttribute('disabled')
    }
  }

  /**
   * Return the song if it exists in the songs array, undefined otherwise
   */
  findSong(songsArray, song) { // skipcq: JS-0105
    return songsArray.find((s) => s.artist === song.artist && s.title === song.title)
  }

  /**
   * Extract the songs from the targets, ensuring there are no duplicates
   */
  extractSongsFromTargets(targets) { // skipcq: JS-0105
    const songs = []

    targets.forEach(target => {
      const artist = target.querySelector('[data-song-artist]').textContent.toLowerCase()

      const titleElement = target.querySelector('[data-song-title]')
      const title = titleElement.textContent.toLowerCase()
      const id = titleElement.getAttribute('data-song-id')

      if (!songs.some((s) => s.id === id)) {
        songs.push({ id, artist, title })
      }
    })

    return songs
  }
}

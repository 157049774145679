$(document).ready(() => {
  $( "#submitSong" ).on( "click", (e) => {
    e.preventDefault();

    let params = $( "#formSong" ).serializeArray();
    let url = $( "#formPath" ).val();
    let redirectUrl = $( "#redirectPath" ).val();

    $.ajax( {
      dataType: "json",
      method: "POST",
      url: url,
      data: params,
      success: (data) => {
        window.location.href = data.redirect_url;
      },
      error: (data) => {
        let response = JSON.parse( data.responseText );
        let items = "";

        $.each( response, (index, error) => {
          items += `<li>${error}</li>`;
        } );

        $( ".errors" ).html( `
          <div class="bg-danger-light p-3">
            <strong>${response.length} errors prohibited this song from being saved:</strong>
            <ul class="mt-3">${items}</ul>
          </div>
        ` );

        $( "#newShift" ).animate( { scrollTop: 0 }, "slow" );
      }
    } );
  });

  // $('[data-remove-song-link]').on('click', function (e) {

  //   let songId = $(this).data('song-id');
  //   console.log(songId);

  //   $.ajax({
  //     dataType: "json",
  //     method: 'GET',
  //     url: "/program_team/songs/" + songId,
  //     success: function (data) {

  //       console.log(data);
  //       $('[data-remove-song-path]').attr('href', data.destroy_path);
  //     },
  //     error: function (data) {
  //       console.log(data);
  //     }
  //   });
  // });

  $('[data-clear-filters-link]').on('click', (e) => {
    e.preventDefault();
    $('[data-song-filter-input]').each(() => {
      {
        $(this).val($(this).data('default'));
        $(this).trigger('change');
      }
    });
  });

  // clear rich-text editor when no-modifided lyrics checkbox is clicked
  $('#has-modified-lyrics-false').on('click', () => {
    window.tinyMCE.get('song_lyric_changes').setContent('');
  });

  $('[data-apply-set-list-songs]').on('click', (e) => {
    e.preventDefault();

    $.ajax({
      url: "/volunteer/set_lists",
      type: "GET",
      dataType: 'json',
      data:
        {
          'song_search_by_artist': $('[data-song-search-by-artist]').val(),
          'song_search_by_title': $('[data-song-search-by-title]').val(),
          'song_genres': $('[data-song-genres]').val(),
          'song_languages': $('[data-song-languages]').val(),
          'song_program_types': $('[data-song-program-types]').val(),
        },

      success: (data) => {
        // data.songs - returns json response from AJAX GET request to set_lists SetListsController index action
        let songList = $('[data-set-list-songs-result]')
        let preselectedItems = songList.select2('data');

        songList.html(data.songs).select2(); // clear (reload) select 2 values which stored before click Apply

        data.songs.forEach(SongsIteration); // iteration
        preselectedItems.forEach(PreselectedIteration); // iteration

        function SongsIteration(song) {
          let newOption = new Option(`${song.artist} - ${song.title}`, song.id, false, false);

          songList.append(newOption).trigger('change.select2'); // add element to list
        }

        function PreselectedIteration(preselected_song) {
          let newOption = new Option(preselected_song.text, preselected_song.id, false, true);

          songList.append(newOption).trigger('change.select2'); // add preselected to list again
        }
      },
      error: (data) => {
        console.log(data);
      }
    });
  });


  // let selected_song_ids_for_set_list = [];
  // $('[data-select-song-for-set-list]').click(function (e) {
  //   e.preventDefault();
  //   let id = $(this).attr('id');
  //   let buttonText = document.getElementById(id);

  //   if (buttonText.innerText === "Select") {
  //     buttonText.innerText = 'Selected';

  //     selected_song_ids_for_set_list.push(id);

  //     $("[data-hidden-button-for-selected-songs]").removeClass("hide-field");

  //   } else if (buttonText.innerText === "Selected") {
  //     buttonText.innerText = 'Select';

  //     for (let i = 0; i < selected_song_ids_for_set_list.length; i++) {
  //       if (selected_song_ids_for_set_list[i] === id) {
  //         selected_song_ids_for_set_list.splice(i, 1);
  //       }
  //     }
  //     if (selected_song_ids_for_set_list.length === 0) {
  //       $("[data-hidden-button-for-selected-songs]").addClass("hide-field");
  //     }
  //   }

  // });

  // $("[data-hidden-button-for-selected-songs]").on('click', function (e) {
  //   e.preventDefault();

  //   $.ajax({
  //     url: "/volunteer/songs",
  //     type: "GET",
  //     dataType: 'script',
  //     data:
  //       {
  //         'selected_songs': selected_song_ids_for_set_list
  //       },
  //     success: function (data) {
  //       console.log(data);
  //     },
  //     error: function (data) {
  //       console.log(data);
  //     }
  //   });
  // });

  let deltaCardTableScroll = $('.card-list-table [class*="col"]').outerWidth();
  let cardNavArrow = $('[data-card-nav-arrow]');
  cardNavArrow.on('click', () => {
    let cardScrollWrapper = $('[data-card-nav-wrapper]');
    let cardMaxScroll = $('[data-card-inner]').outerWidth() - cardScrollWrapper.outerWidth();
    let cardLeftScroll = cardScrollWrapper.scrollLeft();

    if ($(this).data('card-nav-arrow-direction') === 'left')
      cardScrollWrapper.scrollLeft(cardLeftScroll - deltaCardTableScroll);
    else
      cardScrollWrapper.scrollLeft(cardLeftScroll + deltaCardTableScroll);

    cardNavArrow.removeClass('disabled');

    if(cardMaxScroll === cardScrollWrapper.scrollLeft() || cardScrollWrapper.scrollLeft() === 0)
      $(this).addClass('disabled');
  });
});

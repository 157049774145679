$(document).ready(function () {
  
  function toggleShiftBulkEditButtons() {
    const elements = document.querySelectorAll('[data-bulk-edit-shifts]');
    const checked = document.querySelectorAll('.delete-shifts-check:checked').length;
    elements.forEach(element => {
        element.classList.toggle("disabled", checked === 0);
    });
  }

  function toggleSelectedShiftCheckbox(checkbox) {
    const $checkbox = $(checkbox);
    const $row = $checkbox.closest(".program-cards__item-row");
    if (checkbox.checked) {
      $row.addClass("selected");
    } else {
      $row.removeClass("selected");
    }
  }

  function generateShiftListToBulkEdit() {
    $('[data-shift-list]').empty();
    $('.delete-shifts-check:checked').each(function() {
      let programName = $(this).data('program-name');
      let startDate = $(this).data('start-date');
      $('[data-shift-list]').each(function() {
        $(this).append('<tr><td>' + programName + '</td><td>' + startDate + '</td></tr>');
      });
    });
  }

  function disableChangeButton() {
    $("#changeManagerModal #btn-change-manager").addClass("disabled");
    $("#changeManagerModal select")[0].selectedIndex = 0;
  }

  function enableChangeButton() {
    $("#changeManagerModal #btn-change-manager").removeClass("disabled");
  }

  function validateSelectOption() {
    $("#changeManagerModal select").change(function () {
      if ($(this).val() != "") {
        enableChangeButton();
      } else {
        disableChangeButton();
      }
    });
  }

  $('#changeManagerModal').on('show.bs.modal', function () {
    disableChangeButton();
    validateSelectOption();
  });

  $('#bulk-delete-trigger').click(generateShiftListToBulkEdit);
  $('#bulk-assign-pm').click(generateShiftListToBulkEdit);


  $( "#generateCode" ).on( "click", function() {
    let code = document.getElementById( "programCode" );

    $.ajax( {
      dataType: "json",
      method: "GET",
      url: "/generate_code",
      success: function( data ) {
        code.value = data.code;
      }
    } );
  } );

  $( ".clear-filters-link" ).on( "click", function( e ) {
    e.preventDefault();

    $( ".program-filter" ).each( function() {
      if ( $( this ).prop( "type" ) === "checkbox" ) {
        $( this ).prop( "checked", false );
      } else if ( $( this ).prop( "id" ) === "date_range" ) {
        let startDate = new Date( $( "#date_range" ).data( "start-date" ) );
        let endDate = new Date( $( "#date_range" ).data( "end-date" ) );

        $( "input[name=\"date_range\"]" ).daterangepicker( {
          startDate: startDate,
          endDate: endDate,
          opens: "center",
          locale: {
            format: "MM/DD/YYYY"
          }
        } );
      } else {
        $( this ).val( $( this ).data( "default" ) );
        $( this ).trigger( "change" );
      }
    } );
  } );

  $( ".current-date-link" ).on( "click", function( e ) {
    e.preventDefault();
    let target = $( this ).data( "target" );
    let mobileView = $( "#program-calendar-mobile-view" );

    if ( mobileView.length ) {
      $( ".current-date-link" ).removeClass( "active" );
      $( this ).addClass( "active" );

      mobileView.find( ".show" ).removeClass( "show" );
      mobileView.find( "#" + target ).addClass( "show" );

      $( "html, body" ).animate( { scrollTop: mobileView.offset().top }, 500 );
    }
  } );

  // activate tabs trough navigation links
  // not sure it should be here, but only using in program page so far 
  // if we do a tab component, maybe lay it there
  $( "[data-tab-link]" ).on( "click", function (event) {
    let target = $(this).attr("data-tab-link");
    $("[data-target='"+ target+"']").tab('show')
  })

  // Showing Keyboard Location

  $( "#keyboard-location" ).attr( "hidden", !$( "#is-keyboard" ).is( ":checked" ) );

  $( "#is-keyboard" ).on( "click", function() {
    if ( !$( this ).is( ":checked" ) ) {
      $( "#keyboard-location-field" ).val( "" );
    }
    $( "#keyboard-location" ).attr( "hidden", !$( this ).is( ":checked" ) );
  } );


  $( document ).on( "change", ".hospital_check_box", function() {
    let hospitalId = $( this ).data( "hospital-id" );
    let selector = `#selected_hospital_id_${hospitalId}`;
    if ( $( this ).is( ":checked" ) ) {
      console.log( "checked" );

      if ( $( this ).data( "in-person" ) === 1 ) {
        $( ".hospital_check_box:checked" ).not( this ).trigger( "click" );
      }

      $( selector ).addClass( "show-label" );
      $( selector ).removeClass( "hide-label" );
      $( "#hiddenSelectedHospitals" ).append( `<input type="hidden" class="hiddenHospitalInput" id="hiddenHospitalInput${hospitalId}" name="program[hospital_ids][]" value="${hospitalId}"/>` );
    } else {
      console.log( "not checked" );
      $( selector ).removeClass( "show-label" );
      $( selector ).addClass( "hide-label" );
      $( "#hiddenHospitalInput" + hospitalId ).remove();
    }

    if ( $( ".hospital_clipboard_links" ).length ) {
      $( `#hospital_clipboard_links_${hospitalId}` ).attr( "hidden", !$( this ).is( ":checked" ) );
    }
  } );

  $( ".program_type_check_box" ).change( function() {
    let programTypeId = $( this ).data( "program-type-id" );
    let selector = `#selected_program_type_id_${programTypeId}`;
    if ( $( this ).is( ":checked" ) ) {
      $( selector ).addClass( "show-label" );
      $( selector ).removeClass( "hide-label" );
    } else {
      $( selector ).removeClass( "show-label" );
      $( selector ).addClass( "hide-label" );
    }
  } );

  function initHospitalsCheckboxesBehavior() {
    $( "#hospital_id_all" ).click( function() {
      let allChecked = $( "#hospital_id_all" ).prop( "checked" );

      if ( allChecked ) {
        $( ".hospital_check_box:checkbox:not(:checked)" ).trigger( "click" );
      } else {
        $( ".hospital_check_box:checkbox:checked" ).trigger( "click" );
      }
    } );

    $( ".hospital_check_box" ).click( function() {
      if ( !$( this ).prop( "checked" ) ) {
        $( "#hospital_id_all" ).prop( "checked", false );
      }
    } );
  }
  initHospitalsCheckboxesBehavior();

  // HOSPITALS MODAL PAGINATION
  function initHospitalsPagination() {
    let hospitalPagination = $( "[data-hospital-pagination]" );
    if ( hospitalPagination.length > 0 ) {
      hospitalPagination.pagination( {
        pages: $( "#totalPages" ).data( "total-pages" ),
        itemsOnPage: 15,
        cssStyle: "light-theme",
        onPageClick: function( pageNumber, e ) {
          $( ".hospital-page" ).attr( "hidden", true );
          $( `#hospital-page-${pageNumber}` ).attr( "hidden", false );
        }
      } );
    }
  }
  initHospitalsPagination();

  // NEW HOSPITAL FROM PROGRAM PAGE

  $( "#newHospitalFromProgram" ).on( "click", function( e ) {
    e.preventDefault();

    let params = $( "#formProgram" ).serializeArray();

    $.ajax( {
      dataType: "json",
      method: "GET",
      url: "/program_team/programs/save_to_session",
      data: params,
      success: function( data ) {
        window.location.href = "/program_team/hospitals/new";
      },
      error: function( error ) {
        console.log( "error", error );
      }
    } );
  } );

  // NEW PROGRAM -> HOSPITALS MODAL -> FILTERS
  $( "#hospitalsFilters .apply_filters" ).on( "click", function() {

    let params = Object.fromEntries(new URLSearchParams($("#hospitalsFilters .hospital-filter").serialize()));
    params['programType'] = document.querySelector('input[name="program[role]"]').value

    $.ajax( {
      dataType: "html",
      method: "GET",
      url: "/program_team/programs/hospitals",
      data: params,
      success: function( data ) {
        $( "#programHospitalsSelectList" ).html( data );
        $.each( $( ".hiddenHospitalInput" ), function() {
          $( "#hospital_id_" + $( this ).val() ).prop( "checked", true );
        } );
        initHospitalsPagination();
        initHospitalsCheckboxesBehavior();
      },
      error: function( data ) {}
    } );
  } );

  // Add hidden inputs for cached selected checkbox
  $.each($( ".hospital_check_box:checkbox:checked" ), function() {
    let hospitalId = $( this ).val();
    $( "#hiddenSelectedHospitals" ).append(
      `<input type="hidden" class="hiddenHospitalInput" id="hiddenHospitalInput${hospitalId}" name="program[hospital_ids][]" value="${hospitalId}" />`
    );
  });


  if ( $( ".program-card-menu-btn" ).length ) {

    $( document ).on( "click", function( event ) {
      let $target = $( event.target );
      if ( !$target.closest( ".program-card-menu-btn" ).length && $( ".program-card-menu-wrap" ).is( ":visible" ) ) {
        $( ".program-card-menu-wrap" ).removeClass( "active" );
        $( ".program-card-menu-btn" ).removeClass( "active" );
      }
    } );
    $( ".program-card-menu-btn" ).on( "click", function() {
      $( ".program-card-menu-wrap" ).removeClass( "active" );
      $( ".program-card-menu-btn" ).removeClass( "active" );

      let menuID = $( this ).data( "target-menu-id" );
      let menuTarget = $( "[data-menu-id=" + menuID + "]" );

      $( this ).toggleClass( "active" );
      menuTarget.toggleClass( "active" );
    } );
  }

  if ( $( "#btn-edit-shifts-mode" ).length ) {

    $('#bulk-actions-shifts-menu').on('hidden.bs.collapse', function () {
      $( "#shift-select-all-label" ).removeClass( "active" );
      $( ".program-grid" ).removeClass( "select-shifts-visible" );
    })

    $('#bulk-actions-shifts-menu').on('shown.bs.collapse', function () {
      $( "#shift-select-all-label" ).addClass( "active" );
      $( ".program-grid" ).addClass( "select-shifts-visible" );
    })

    

    $("#select_all_check").on("change", function () {
      let selectAllLabelElement = document.getElementById('shift-select-all-label')
      let selectAllLabel = (this.checked) ? 'Unselect all' : 'Select all';
      selectAllLabelElement.innerHTML = selectAllLabel
      toggleShiftBulkEditButtons()
    })

    $("#select_all_check").on("click", function () {
      let checkboxes = $( ".delete-shifts-check" );
      for ( let i = 0, n = checkboxes.length; i < n; i++ ) {
        checkboxes[ i ].checked = $( this )[ 0 ].checked;

        if ( checkboxes[ i ].checked ) {
          $( checkboxes[ i ] ).closest( ".program-cards__item-row" ).addClass( "selected" );
        } else {
          $( checkboxes[ i ] ).closest( ".program-cards__item-row" ).removeClass( "selected" );
        }
      }
    } );

    $( ".delete-shifts-check" ).on( "click", function() {
      const checkboxes = $(".delete-shifts-check");
      const selectAllLink = $("#shift-select-all-label");
      const checkAllCheckbox = $("#select_all_check")[0];
      
      checkboxes.each(function() {
        toggleSelectedShiftCheckbox(this);
      });


      if (checkboxes.length === checkboxes.filter(":checked").length) {
        checkAllCheckbox.checked = true;
        selectAllLink.text("Unselect All");
      } else {
        checkAllCheckbox.checked = false;
        selectAllLink.text("Select All");
      }

      toggleShiftBulkEditButtons()

    } );
  }

  $( "#confirmSelectProgramForShift" ).on( "click", function() {
    let programId = $( "#selected_vp_for_shift" ).val();
    window.location.href = `/program_team/programs/${programId}?add_shift_modal=true`;
  } );
} );

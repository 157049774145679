import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import Rails from "@rails/ujs"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      onEnd: this.end.bind(this)
    }) 
  }

  end(event) {
    const data = new FormData()
    data.append("position", event.newIndex + 1)
    data.append("item_id", event.item.dataset.itemId)

    Rails.ajax({
      url: this.data.get("url"),
      type: "PATCH",
      data,
      error: () => {
        window.notifier.alert('There was an error while sorting the songs. Please try again.');
      }
    })
  }
}
